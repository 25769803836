.create-room {
  width: 50%;
  --background: var(--ion-color-secondary-shade);
  --background-activated: var(--ion-color-secondary);
  --background-focused: var(--ion-color-secondary);
}

.intro-col {
  color: var(--ion-color-primary);
}

.intro-header {
  font-size: 36px;
  margin-top: 40px;
}

.support-col {
  margin-top: 30px;
}

.request-col {
  margin-top: 30px;
}

.home-content {
  --background: var(--ion-color-light-shade);
}

.home-grid {
  font-family: 'custom-head';
  max-width: 800px;
  font-size: 26px;
  color: var(--ion-color-secondary);
  text-align: center;
}

ion-toolbar {
  text-align: center;
}

ion-title {
  font-size: 28px;
  font-style: oblique;
  color: var(--ion-color-primary);
}

.first-step-col {
  margin-top: 30px;
}

.create-col {
  margin-bottom: 20px;
}

.share-col {
  margin-top: 2px;
  font-size: 20px;
  margin-bottom: 50px;
}

.join-col {
  font-size: 20px;
}

.paste-col {
  padding: 0;
  margin-bottom: 50px;
}

.paste-toolbar {
  max-width: 300px;
  --background: none;
  margin: auto;
}

.paste-input {
  margin-left: 4px;
  text-align: left;
  font-size: 14px;
  border: 1px solid var(--ion-color-secondary);
  border-radius: 5px;
}

.paste-button {
  margin-right: 2px;
  --box-shadow: 0 3px 2px -1px rgba(0, 0, 0, 0.2), 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
