.settings-header {
  text-align: left;
}

.name-item {
  font-size: 14px;
  color: var(--ion-color-secondary);
}

.name-input {
  color: #fff;
}

.error-message {
  padding-top: 4px;
  padding-left: 16px;
  color: #e61a61;
}

.about-grid {
  margin-top: 20px;
  font-size: 20px;
  font-style: bold;
  color: var(--ion-color-secondary);
  text-align: center;
}

.externals-row {
  margin-top: 25px;
}

.about-icons {
  width: 40px;
  height: 40px;
  padding: 0;
  top: 0;
  cursor: pointer;
  color: var(--ion-color-secondary);
}
