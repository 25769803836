.title {
  text-align: left;
  max-width: 110px;
  padding-left: 15px;
  padding-right: 15px;
  color: var(--ion-color-primary);
  cursor: pointer;
}

.input-bar {
  min-width: 200px;
  align-self: center;
}

ion-icon {
  width: 24px;
  height: 24px;
  padding: 5px;
}

.native-input.sc-ion-input-ios {
  padding-right: 0;
}
